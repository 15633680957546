<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip-class
            :editable="editable"
            type="search"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="중요도"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-dept label="관리부서" type="search" name="managementDepts" v-model="searchParam.managementDepts" />
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_CYCLE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="inspectionCycleCd"
            label="법정점검주기"
            v-model="searchParam.inspectionCycleCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            codeGroupCd="MDM_MAINTENANCE_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckTypeCd"
            label="정비구분"
            v-model="searchParam.equipmentCheckTypeCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비 중요도 및 법정점검주기"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="equipmentTypeCd"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn label="등록" icon="add_circle" /> -->
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'mim',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantNm' },
          { index: 1, colName: 'upEquipmentTypeNm' },
          { index: 2, colName: 'equipmentTypeNm' },
        ],
        columns: [
          {
            name: 'plantNm',
            field: 'plantNm',
            label: '사업장',
            style: 'width:80px',
            align: 'center',
            sortable: false,
            // rowspan: 5,
          },
          {
            name: 'upEquipmentTypeNm',
            field: 'upEquipmentTypeNm',
            label: '대분류',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            label: '소분류',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'grade',
            field: 'grade',
            label: '중요도',
            style: 'width:80px',
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'MEGC000001', stepperMstNm: 'A등급', colorClass: 'blue' },
              { stepperMstCd: 'MEGC000005', stepperMstNm: 'B등급', colorClass: 'green' },
              { stepperMstCd: 'MEGC000010', stepperMstNm: 'C등급', colorClass: 'orange' },
              { stepperMstCd: 'MEGC000015', stepperMstNm: 'D등급', colorClass: 'gray' },
            ],
            align: 'center',
            sortable: false,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentCdNames',
            field: 'equipmentCdNames',
            label: '해당설비코드',
            style: 'width:350px',
            align: 'left',
            sortable: false,
            type: "link",
          },
          {
            name: 'processNames',
            field: 'processNames',
            label: '공정',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            label: '법정점검주기',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'dailyInspection',
                field: 'dailyInspection',
                label: '일상',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'twoWeeks',
                field: 'twoWeeks',
                label: '2주',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'oneMonth',
                field: 'oneMonth',
                label: '1개월',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'twoMonth',
                field: 'twoMonth',
                label: '3개월',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'sixMonth',
                field: 'sixMonth',
                label: '6개월',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'annualRemuneration',
                field: 'annualRemuneration',
                label: '년차보수',
                align: 'center',
                sortable: false,
                style: 'width:65px',
                type: "link",
              },
            ]
          },
          {
            label: '정비구분',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'operationMaintenance',
                field: 'operationMaintenance',
                label: '운전',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'preventiveMaintenance',
                field: 'preventiveMaintenance',
                label: '예방',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'predictiveMaintenance',
                field: 'predictiveMaintenance',
                label: '예측',
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'breakdownMaintenance',
                field: 'breakdownMaintenance',
                label: '고장',
                align: 'center',
                sortable: false,
                type: "link",
              },
            ]
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        grade: null,
        inspectionCycleCd: null,
        equipmentCheckTypeCd: null,
        managementDepts: '',
        equipmentTypeCd: '',
        equipmentCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
      this.listUrl = selectConfig.sop.min.equipment.checkCycle.list.url;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },
      );
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      this.equipmentPopup(row, col);
    },
    equipmentPopup(result, col) {
      let selectData = this.$refs['table'].selected;

      if (!col.value) return;
      let title = '';
      let param = null;

      let code = '';
      if (col.name === 'dailyInspection') code = 'MECC000001'
      else if (col.name === 'twoWeeks') code = 'MECC000005'
      else if (col.name === 'oneMonth') code = 'MECC000010'
      else if (col.name === 'twoMonth') code = 'MECC000015'
      else if (col.name === 'sixMonth') code = 'MECC000020'
      else if (col.name === 'annualRemuneration') code = 'moreThanYear';

      else if (col.name === 'operationMaintenance') code = 'MMKC00020' // 운전
      else if (col.name === 'preventiveMaintenance') code = 'MMKC00005' // 예방
      else if (col.name === 'predictiveMaintenance') code = 'MMKC00010' // 예측
      else if (col.name === 'breakdownMaintenance') code = 'MMKC00015' // 고장
      // 전체조회
      if (col.name === 'equipmentCds') {
        param = { 
          equipmentTypeCd: result.equipmentTypeCd,
          grade: result.grade,
          managementDepts: result.managementDepts,
          inspectionCycleCd: '',
        }
        title = '중요도별 설비 목록';
        this.popupOptions.target = () =>
        import(`${"./checkCycleEquipPopup.vue"}`);
        this.popupOptions.isFull = false;
        this.popupOptions.title = title;
        this.popupOptions.param = param;
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'operationMaintenance' || col.name === 'preventiveMaintenance' 
      || col.name === 'predictiveMaintenance' || col.name === 'breakdownMaintenance') {
        param = { 
            equipmentTypeCd: result.equipmentTypeCd,
            grade: result.grade,
            equipmentCheckTypeCd: code,
          };
          title = '설비정비목록 검색';
          this.popupOptions.target = () =>
          import(`${"./checkCycleEquipMaintenancePopup.vue"}`);
          this.popupOptions.isFull = false;
          this.popupOptions.title = title;
          this.popupOptions.param = param;
          this.popupOptions.width = '80%';
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closePopup;
      } else {
        param = { 
          equipmentTypeCd: result.equipmentTypeCd,
          grade: result.grade,
          managementDepts: result.managementDepts,
          inspectionCycleCd: code,
        };
        title = '법정점검주기별 설비 목록';
        this.popupOptions.target = () =>
        import(`${"./checkCycleEquipPopup.vue"}`);
        this.popupOptions.isFull = false;
        
        this.popupOptions.title = title;
        this.popupOptions.param = param;
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // this.getList();
    },
  }
};
</script>
